import { FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { JSONWidgetType as TeaserStandardHorizontalJSONWidgetType } from '@widgets/TeaserStandardHorizontal'
import { JSONWidgetType as RelatedMatchesJSONWidgetType } from '@widgets/RelatedMatches'
import ImageComponent from '@components/HeroModule/Image'
import RelatedItemsWrapperMobile from '@components/HeroModule/RelatedItemsWrapperMobile'
import RelatedMatchesWrapperMobile from '@components/HeroModule/RelatedMatchesWrapperMobile'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'
import useSportsEvent from '@hooks/useSportsEvent'
import { isLiveStream } from './Video/utils'
import SportsFooter from '@components/SportsFooter/toi'
import Blink from '@components/Blink'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import {
  desktopCSS,
  mobileCSS,
  layout,
  mobileAndTabletCSS,
} from '@measures/responsive'
import VideoLabel from '@components/VideoLabel'
import Label from '@components/TeaserToi/Label'
import BulletPoints from '@components/TeaserToi/BulletPoints'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import Pop from '@components/TeaserToi/Pop'
import VideoBackground from '@components/TeaserToi/VideoBackground'
import Badge from '@components/TeaserToi/Badge'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import BlinkingDot from '@components/BlinkingDot'
import Catchword from '@components/TeaserToi/Catchword'
import BlickPlusLogo from '@components/TeaserToi/BlickPlusLogo'
import Title from '@components/TeaserToi/Title'
import Subtitle from '@components/TeaserToi/Subtitle'
import NewsTicker from '@components/TeaserToi/NewsTicker'
import JSONRenderer from '@components/JSONRenderer'
import { ViewportTypeValues } from '@hooks/useViewport/types'
import BlurryBackgroundContext from '@contexts/blurryBackground'
import InvertedColorsContext from '@contexts/invertedColors'
import RelatedItemsDesktop from '@components/HeroModule/RelatedItemsDesktop'
import ShowMoreButton from '@components/Buttons/ShowMoreButton'
import TeaserBackgroundVideo from '@components/TeaserBackgroundVideo'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import SportsPartner from '@components/HeroModule/SportsPartner'
import ImmersiveDynamicContentWrapper from '@components/HeroModule/ImmersiveDynamicContentWrapper'
import RelatedContentWrapperDesktop from '@components/HeroModule/RelatedContentWrapperDesktop'
import DynamicContentWrapper from '@components/HeroModule/ DynamicContentWrapper'
import useDeviceType from '@hooks/useDeviceType'
import HeroModuleContext from '@contexts/heroModuleContext'

export interface POP {
  image: Image<'1_1'>
  position: {
    v: 'top' | 'bottom'
    h: 'left' | 'right'
  }
}

export interface HeroModuleNoBulletPointProps {
  bulletpoints?: undefined
  subtitle?: string
}

export interface HeroModuleBulletPointProps {
  bulletpoints: string[]
}

export type HeroModuleMaybeBulletPointProps =
  | HeroModuleNoBulletPointProps
  | HeroModuleBulletPointProps

export type HeroModuleCommonProps = {
  accentColor: string
  title: string
  catchword?: string
  labelText?: string
  pop?: POP
  video?: TeaserVideo
  link: Link
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  track?: boolean
  isPlus?: boolean
  articleId?: string
  targetContentType: TargetContentType
  teaserVideo?: TeaserVideo
  cueLiveId?: string
  partner?: {
    name: string
    logo: Image<'free'>
    title: string
    url: string
  }
}

export interface HeroModuleNoSportProps {
  typeOfSport?: undefined
  badge?: {
    text: string
    isLive?: boolean
    backgroundColor: string
  }
}

export interface HeroModuleSportProps {
  typeOfSport: Exclude<LiveEventDataSportTypes, 'NO_SPORT'>
  matchId: string
}

export type HeroModuleMaybeSportProps =
  | HeroModuleNoSportProps
  | HeroModuleSportProps

export interface HeroModuleNoRelatedItemsProps {
  relatedItems?: undefined
  relatedMatches?: RelatedMatchesJSONWidgetType
}

export interface HeroModuleRelatedItemsProps {
  relatedItems: TeaserStandardHorizontalJSONWidgetType[]
  relatedItemsTitle?: string
}

export type HeroModuleMaybeRelatedItemsProps =
  | HeroModuleNoRelatedItemsProps
  | HeroModuleRelatedItemsProps

export interface HeroModuleNoImmersiveProps {
  isImmersive?: false
  image: Image<'3_4' | '3_2'>
}

export interface HeroModuleImmersiveProps {
  isImmersive: true
  image: Image<'5_8' | '3_2'>
}

export type HeroModuleMaybeImmersiveProps =
  | HeroModuleNoImmersiveProps
  | HeroModuleImmersiveProps

export type HeroModuleAPIProps = HeroModuleCommonProps &
  HeroModuleMaybeBulletPointProps &
  HeroModuleMaybeSportProps &
  HeroModuleMaybeImmersiveProps &
  HeroModuleMaybeRelatedItemsProps

export type StyledPopProps = Required<HeroModuleAPIProps>['pop']['position'] & {
  hasRelatedContent: boolean
}

export interface StyledImageComponentProps {
  hasSport: boolean
  isImmersive: boolean
}

export type BackgroundVideoType =
  | 'fit-to-width'
  | 'stretched-to-fill'
  | undefined

export interface PopStackProps {
  hasRelatedItems: boolean
  hasRelatedMatches: boolean
}

export interface GradientStackProps {
  hasDynamicContent: boolean
  isImmersive: boolean
}

export interface MobileGradientStackProps {
  shouldShowDarkGradient: boolean
}

export interface ExtraRelatedItemsWrapperProps {
  isExpanded: boolean
  amountOfItemsToExpand: number
}

export interface GridWrapperProps {
  isImmersive: boolean
}

export interface StyledBadgeProps {
  isImmersive: boolean
}

const {
  header: {
    mobileAndTablet: { totalOffset: headerTotalOffsetMobileAndTablet },
  },
} = layout

const TOP_OFFSET = 23

const StyledHeroModule = styled.div`
  position: relative;
`

const StyledTeaserWrapper = styled.div`
  position: relative;

  ${desktopCSS(css`
    margin-left: -15px;
    margin-right: -15px;
  `)};

  ${mobileCSS(css`
    margin-left: -16px;
    margin-right: -16px;
  `)};
`

const getTOISportsFooterMinHeightForSportAndViewport = (
  typeOfSport: LiveEventDataSportTypes,
  platform: ViewportTypeValues
): string => {
  if (typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.TENNIS) {
    if (platform === 'desktop') {
      return '94px'
    }

    return '86px'
  } else if (
    typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1 ||
    typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SKI
  ) {
    if (platform === 'desktop') {
      return '72px'
    }

    return '138px'
  } else {
    if (platform === 'desktop') {
      return '94px'
    }

    return '98px'
  }
}

const TextStack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  justify-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;

  ${desktopCSS(css`
    padding: 0;
    width: calc(632px - 2 * 24px);
  `)};
`

const GradientStack = styled.div<GradientStackProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      spacing: { spacing16, spacing24 },
    },
    hasDynamicContent,
    isImmersive,
  }) => css`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
    justify-items: flex-start;
    padding: 0 ${spacing16}
      ${hasDynamicContent && !isImmersive ? '0px' : spacing24} ${spacing16};

    ${desktopCSS(css`
      padding: 0 15px ${spacing24} 15px;

      &:before {
        content: '';
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: calc(100% + 80px);
        background: linear-gradient(
          2deg,
          ${primary01Color}99 70%,
          ${primary01Color}00 85%
        );
        ${desktopCSS(css`
          background: radial-gradient(
            70% 90% at 20% 90%,
            ${primary01Color}99 70%,
            ${primary01Color}00
          );
        `)}
        z-index: -1;
      }
    `)};
  `}
`
const MobileGradientStack = styled.div<MobileGradientStackProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      spacing: { spacing16, spacing24 },
    },
    shouldShowDarkGradient,
  }) => {
    const styles = css`
      &:before {
        content: '';
        position: absolute;
        top: -80px;
        left: -${spacing16};
        width: calc(100% + 2 * ${spacing16});
        height: calc(100% + 80px + ${spacing24});
        z-index: -1;
        background: ${shouldShowDarkGradient
          ? `linear-gradient(2deg, ${primary01Color} 10%, ${primary01Color}99 60%,${primary01Color}00 90%)`
          : `linear-gradient(2deg, ${primary01Color}99 60%, ${primary01Color}00 90%)`};
      }
    `
    return css`
      ${mobileAndTabletCSS(styles)}
    `
  }}
`

const PopStack = styled.div<PopStackProps>`
  ${({ hasRelatedItems, hasRelatedMatches }) => css`
    position: relative;
    width: 100%;
    z-index: 2;
    ${desktopCSS(css`
      width: ${hasRelatedItems
        ? 'calc(100% - 332px)'
        : hasRelatedMatches
          ? 'calc(100% - 300px)'
          : '100%'};
    `)};
  `};
`

const StyledBadge = styled(Badge)<StyledBadgeProps>`
  ${({
    theme: {
      spacing: { spacing24 },
    },
    isImmersive,
  }) => css`
    position: absolute;
    top: ${spacing24};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    ${isImmersive &&
    css`
      ${mobileAndTabletCSS(css`
        top: calc(${headerTotalOffsetMobileAndTablet}px + ${TOP_OFFSET}px);
      `)};
    `};
  `}
`

const StyledSportBadge: FunctionComponent<{
  isLive: boolean
  children: ReactNode
  isImmersive: boolean
}> = ({ isLive, children, isImmersive }) => {
  const theme = useTheme()
  return (
    <StyledBadge
      isImmersive={isImmersive}
      backgroundColor={
        isLive
          ? theme.color.primary.blickRed
          : theme.color.secondary.greenAccessible
      }>
      {children}
    </StyledBadge>
  )
}

const StyledPop = styled(Pop)<StyledPopProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing20, spacing24, spacing32 },
    },
    v,
    h,
    hasRelatedContent,
  }) => css`
    position: absolute;
    ${v === 'bottom'
      ? css`
          bottom: ${spacing20};
        `
      : css`
          top: ${spacing24};
        `}
    ${h === 'left' ? 'left' : 'right'}: ${spacing16};
    ${desktopCSS(css`
      ${h === 'left' || hasRelatedContent ? 'left' : 'right'}: 15px;
      ${v === 'bottom'
        ? css`
            bottom: ${spacing32};
          `
        : css`
            top: ${spacing24};
          `}
    `)}
  `}
`

const StyledBlinkingDot = styled(BlinkingDot)`
  ${({
    theme: {
      spacing: { spacing2, spacing4 },
    },
  }) => css`
    margin-right: calc(${spacing4} + ${spacing2});
    margin-left: ${spacing2};
    height: 14px;
  `}
`

const GridWrapper = styled.div<GridWrapperProps>`
  ${({ isImmersive }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-columns: minmax(0, 1fr);
    justify-items: flex-start;

    ${isImmersive &&
    css`
      box-sizing: border-box;

      ${mobileAndTabletCSS(css`
        padding-top: calc(
          ${headerTotalOffsetMobileAndTablet}px + ${TOP_OFFSET}px
        );
      `)};
    `};
  `}
`

const StyledImageComponent = styled(ImageComponent)<StyledImageComponentProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
    hasSport,
    isImmersive,
  }) => css`
    ${hasSport &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 60px;
        background: linear-gradient(
          180deg,
          ${primary01Color}BF 0%,
          ${primary01Color}00 100%
        );
        ${isImmersive &&
        css`
          ${mobileAndTabletCSS(css`
            display: none;
          `)};
        `};
      }
    `}
  `}
`

const StyledLabel = styled(Label)`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    margin-bottom: ${spacing8};
    margin-left: ${spacing16};
    z-index: 2;
    ${desktopCSS(css`
      margin-left: 15px;
    `)};
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    margin-bottom: ${spacing8};
    margin-left: ${spacing16};
    z-index: 2;
    ${desktopCSS(css`
      margin-left: 15px;
    `)};
  `}
`

const StyledBulletPoints = styled(BulletPoints)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-top: ${spacing8};
  `}
`

const ClickableArea = styled(Blink)`
  display: block;
  appearance: none;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ExtraRelatedItemsWrapper = styled.div<ExtraRelatedItemsWrapperProps>`
  ${({
    theme: {
      spacing: { spacing16 },
    },
    amountOfItemsToExpand,
    isExpanded,
  }) => css`
    overflow-y: hidden;
    max-height: ${isExpanded
      ? amountOfItemsToExpand > 1
        ? '268px'
        : '126px'
      : 0};
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing16};
  `}
`

const StyledShowMoreButton = styled(ShowMoreButton)`
  justify-self: center;
`

interface StyledVideoBackgroundProps {
  isVisible: boolean
  videoType: BackgroundVideoType
  shouldHaveLargePadding: boolean
}

const StyledVideoBackground = styled(
  VideoBackground
)<StyledVideoBackgroundProps>`
  ${({ isVisible, videoType, shouldHaveLargePadding }) => css`
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
    ${shouldHaveLargePadding &&
    mobileAndTabletCSS(css`
      padding-top: calc(
        ${headerTotalOffsetMobileAndTablet}px + ${TOP_OFFSET}px
      );
    `)}
    ${videoType === 'fit-to-width' &&
    desktopCSS(css`
      padding-left: 15px;
      padding-right: 15px;
    `)};
  `}
`

const HeroModule: FunctionComponent<HeroModuleAPIProps> = (props) => {
  const {
    title,
    link,
    image,
    targetContentType,
    relatedItems,
    pop,
    accentColor,
    catchword,
    isPlus,
    typeOfSport,
    video,
    labelText,
    teaserVideo,
    bulletpoints,
    cueLiveId,
    isImmersive,
    partner,
  } = props

  const isValidSport = !!typeOfSport

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? props.matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
  })

  const ref = useAnimatedPreview({
    video,
    articleUrl: link.href,
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined

  const hasRelatedContent = !!relatedItems || !!props.relatedMatches

  const shouldShowSportsComponents = isValidSport && !!sportsData

  const showCatchword = !!isPlus || !!catchword

  const deviceType = useDeviceType()
  const shouldShowBackgroundVideo = !!teaserVideo && deviceType !== 'ios'

  const isBackgroundVideoLiveStream =
    shouldShowBackgroundVideo && isLiveStream(teaserVideo?.duration)

  const backgroundVideoType = isBackgroundVideoLiveStream
    ? 'fit-to-width'
    : 'stretched-to-fill'

  const [hasBackgroundVideoLoaded, setHasBackgroundVideoLoaded] =
    useState<boolean>(false)

  const [
    areMoreRelatedItemsExpandedForMobile,
    setAreMoreRelatedItemsExpandedForMobile,
  ] = useState<boolean>(false)

  const onVideoPlay = useCallback(() => {
    setHasBackgroundVideoLoaded(true)
  }, [])

  const onVideoStop = useCallback(() => {
    setHasBackgroundVideoLoaded(false)
  }, [])

  const hasNewsTicker = !!cueLiveId

  const shouldShowDarkGradient = shouldShowSportsComponents || hasNewsTicker

  return (
    <HeroModuleContext.Provider value={true}>
      <StyledHeroModule>
        <StyledTeaserWrapper ref={ref}>
          <StyledImageComponent
            hasSport={isValidSport}
            image={image}
            isImmersive={!!isImmersive}>
            {shouldShowBackgroundVideo && (
              <StyledVideoBackground
                videoType={backgroundVideoType}
                isVisible={hasBackgroundVideoLoaded}
                shouldHaveLargePadding={
                  isBackgroundVideoLiveStream && !!isImmersive
                }>
                <TeaserBackgroundVideo
                  videoId={teaserVideo.jwVideoId}
                  isLiveStream={isBackgroundVideoLiveStream}
                  onPlay={onVideoPlay}
                  onStop={onVideoStop}
                />
              </StyledVideoBackground>
            )}
            {!shouldShowBackgroundVideo && !!video && (
              <TeaserAnimatedPreview video={video} articleUrl={link.href} />
            )}
            <ClickableArea {...link}>
              {!isValidSport && !!props.badge && (
                <StyledBadge
                  backgroundColor={props.badge.backgroundColor}
                  isImmersive={!!isImmersive}>
                  {!!props.badge.isLive && <StyledBlinkingDot />}
                  {props.badge.text}
                </StyledBadge>
              )}
              <GridWrapper isImmersive={!!isImmersive}>
                {shouldShowSportsComponents && (
                  <StyledSportBadge
                    isLive={sportsData?.isLive}
                    isImmersive={!!isImmersive}>
                    <SportLabelContent sportsData={sportsData} />
                  </StyledSportBadge>
                )}
                <PopStack
                  hasRelatedItems={!!relatedItems}
                  hasRelatedMatches={!relatedItems && !!props.relatedMatches}>
                  {!!pop && (
                    <StyledPop
                      {...pop.position}
                      {...pop.image}
                      hasRelatedContent={hasRelatedContent}
                    />
                  )}
                </PopStack>
                {!!video && (
                  <StyledVideoLabel
                    duration={video.duration}
                    targetContentType={targetContentType}
                  />
                )}
                {!!labelText && (
                  <StyledLabel labelColor={accentColor}>
                    {labelText}
                  </StyledLabel>
                )}
                <GradientStack
                  hasDynamicContent={isValidSport || hasNewsTicker}
                  isImmersive={!!isImmersive}>
                  <TextStack>
                    <MobileGradientStack
                      shouldShowDarkGradient={shouldShowDarkGradient}>
                      {showCatchword && (
                        <Catchword>
                          {isPlus && <BlickPlusLogo />}
                          {catchword}
                        </Catchword>
                      )}
                      <Title>{title}</Title>
                      {!!bulletpoints && (
                        <StyledBulletPoints accentColor={accentColor}>
                          {bulletpoints}
                        </StyledBulletPoints>
                      )}
                      {!bulletpoints && !!props.subtitle && (
                        <Subtitle>{props.subtitle}</Subtitle>
                      )}
                    </MobileGradientStack>
                  </TextStack>
                  <DynamicContentWrapper
                    hideOnMobile={!!isImmersive}
                    typeOfSport={typeOfSport}
                    isSportDataLoaded={!!sportsData}>
                    {shouldShowSportsComponents && (
                      <SportsFooter sportsData={sportsData} />
                    )}
                    {hasNewsTicker && (
                      <NewsTicker cueLiveId={cueLiveId} isInHeroModule={true} />
                    )}
                  </DynamicContentWrapper>
                </GradientStack>
              </GridWrapper>
            </ClickableArea>
            {hasRelatedContent && (
              <RelatedContentWrapperDesktop
                hasNewsticker={hasNewsTicker}
                typeOfSport={typeOfSport}>
                <BlurryBackgroundContext.Provider value={true}>
                  <InvertedColorsContext.Provider value={true}>
                    {!!relatedItems ? (
                      <RelatedItemsDesktop
                        title={props.relatedItemsTitle}
                        accentColor={accentColor}
                        items={relatedItems}
                      />
                    ) : (
                      <JSONRenderer>{props.relatedMatches}</JSONRenderer>
                    )}
                  </InvertedColorsContext.Provider>
                </BlurryBackgroundContext.Provider>
              </RelatedContentWrapperDesktop>
            )}
          </StyledImageComponent>
        </StyledTeaserWrapper>
        {!!isImmersive && (
          <ImmersiveDynamicContentWrapper
            {...link}
            typeOfSport={typeOfSport}
            isSportDataLoaded={!!sportsData}>
            {shouldShowSportsComponents && (
              <SportsFooter sportsData={sportsData} />
            )}
            {hasNewsTicker && <NewsTicker cueLiveId={cueLiveId} />}
          </ImmersiveDynamicContentWrapper>
        )}
        <SportsPartner partner={partner} />
        {!!relatedItems && (
          <RelatedItemsWrapperMobile>
            <JSONRenderer>{relatedItems[0]}</JSONRenderer>
            {!!relatedItems[1] && (
              <JSONRenderer>{relatedItems[1]}</JSONRenderer>
            )}
            {relatedItems.length > 2 && (
              <>
                <ExtraRelatedItemsWrapper
                  isExpanded={areMoreRelatedItemsExpandedForMobile}
                  amountOfItemsToExpand={relatedItems.length - 2}>
                  <JSONRenderer>{relatedItems[2]}</JSONRenderer>
                  {!!relatedItems[3] && (
                    <>
                      <JSONRenderer>{relatedItems[3]}</JSONRenderer>
                    </>
                  )}
                </ExtraRelatedItemsWrapper>
                <StyledShowMoreButton
                  isMore={!areMoreRelatedItemsExpandedForMobile}
                  size="large"
                  onClick={() => {
                    setAreMoreRelatedItemsExpandedForMobile(
                      (areMoreRelatedItemsExpandedForMobile) =>
                        !areMoreRelatedItemsExpandedForMobile
                    )
                  }}
                />
              </>
            )}
          </RelatedItemsWrapperMobile>
        )}
        {!props.relatedItems && !!props.relatedMatches && (
          <RelatedMatchesWrapperMobile>
            <JSONRenderer>{props.relatedMatches}</JSONRenderer>
          </RelatedMatchesWrapperMobile>
        )}
      </StyledHeroModule>
    </HeroModuleContext.Provider>
  )
}

export { getTOISportsFooterMinHeightForSportAndViewport }

const widget = {
  kind: ['hero-module'],
  component: HeroModule,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
